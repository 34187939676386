import {
  GET_SCHOOL_CALENDER,
  GET_NOTIFICATIONS_ANNOUNCEMENT,
  GET_TIME_TABLE,
  GET_COURSE_PLAN,
  GET_NOTIFICATIONS_SMS,
  GET_NOTIFICATIONS_MOBILE,
  GET_SHORT_REPORT,
  GET_NOTIFICATIONS_MOBILE_TOTALS_STUDENTS,
  GET_NOTIFICATIONS_MOBILE_TOTALS_STUDENT_DETAILS,
  GET_NOTIFICATION_BY_TEACHER
} from '../constants/LmsRolesArea';

const initState = {
  schoolCalenderList: [],
  schoolCalenderListStatus: false,
  schoolCalenderListMsg: '',
  schoolCalenderListTotalItems: 0,
  schoolCalenderListCurrentPage: 0,

  notificationsAnnouncementList: [],
  notificationsAnnouncementListStatus: false,
  notificationsAnnouncementListMsg: '',
  notificationsAnnouncementListTotalItems: 0,
  notificationsAnnouncementListCurrentPage: 0,

  timeTableList: [],
  timeTableListStatus: false,
  timeTableListMsg: '',
  timeTableListTotalItems: 0,
  timeTableListCurrentPage: 0,

  coursePlanList: [],
  coursePlanListStatus: false,
  coursePlanListMsg: '',
  coursePlanListTotalItems: 0,
  coursePlanListCurrentPage: 0,

  notificationsSmsList: [],
  notificationsSmsListStatus: false,
  notificationsSmsListMsg: '',
  notificationsSmsListTotalItems: 0,
  notificationsSmsListCurrentPage: 0,

  notificationsMobileList: [],
  notificationsMobileListStatus: false,
  notificationsMobileListMsg: '',
  notificationsMobileListTotalItems: 0,
  notificationsMobileListCurrentPage: 0,

  shortReportList: [],
  shortReportListStatus: false,
  shortReportListMsg: '',
  shortReportListTotalItems: 0,
  shortReportListCurrentPage: 0,
  notificationByTeacher:{}
};

const adminArea = (state = initState, action) => {
  const {type, payload} = action;
  switch (type) {
    case GET_SCHOOL_CALENDER:
      return {
        ...state,
        schoolCalenderList: payload?.data?.result || [],
        schoolCalenderListStatus: payload?.status || false,
        schoolCalenderListMsg: payload?.msg || 'Something Went Wrong',
        schoolCalenderListTotalItems: payload?.data?.totalItems || 0,
        schoolCalenderListCurrentPage: payload?.data?.currentPage || 0
      };
    case GET_NOTIFICATIONS_ANNOUNCEMENT:
      return {
        ...state,
        notificationsAnnouncementList: payload?.data?.result || [],
        notificationsAnnouncementListStatus: payload?.status || false,
        notificationsAnnouncementListMsg: payload?.msg || 'Something Went Wrong',
        notificationsAnnouncementListTotalItems: payload?.data?.totalItems || 0,
        notificationsAnnouncementListCurrentPage: payload?.data?.currentPage || 0
      };
    case GET_TIME_TABLE:
      return {
        ...state,
        timeTableList: payload?.data?.result || [],
        timeTableListStatus: payload?.status || false,
        timeTableListMsg: payload?.msg || 'Something Went Wrong',
        timeTableListTotalItems: payload?.data?.totalItems || 0,
        timeTableListCurrentPage: payload?.data?.currentPage || 0
      };
    case GET_COURSE_PLAN:
      return {
        ...state,
        coursePlanList: payload?.data?.result || [],
        coursePlanListStatus: payload?.status || false,
        coursePlanListMsg: payload?.msg || 'Something Went Wrong',
        coursePlanListTotalItems: payload?.data?.totalItems || 0,
        coursePlanListCurrentPage: payload?.data?.currentPage || 0
      };
    case GET_NOTIFICATIONS_SMS:
      return {
        ...state,
        notificationsSmsList: payload?.data?.result || [],
        notificationsSmsListStatus: payload?.status || false,
        notificationsSmsListMsg: payload?.msg || 'Something Went Wrong',
        notificationsSmsListTotalItems: payload?.data?.totalItems || 0,
        notificationsSmsListCurrentPage: payload?.data?.currentPage || 0
      };
    case GET_NOTIFICATIONS_MOBILE:
      return {
        ...state,
        notificationsMobileList: payload?.data?.result || [],
        notificationsMobileListStatus: payload?.status || false,
        notificationsMobileListMsg: payload?.msg || 'Something Went Wrong',
        notificationsMobileListTotalItems: payload?.data?.totalItems || 0,
        notificationsMobileListCurrentPage: payload?.data?.currentPage || 0
      };
    case   GET_NOTIFICATIONS_MOBILE_TOTALS_STUDENTS:
      return {
        ...state,
        notificationsTotalsStudents: payload?.data?.result || [],
        notificationsTotalsStudentsStatus: payload?.status || false,
        notificationsTotalsStudentsMsg: payload?.msg || 'Something Went Wrong',
        notificationsTotalsStudentsTotalItems: payload?.data?.totalItems || 0,
        notificationsTotalsStudentsCurrentPage: payload?.data?.currentPage || 0,
        notificationsTotalsStudentsOffset: payload?.data?.offset || 0
      };
    case GET_NOTIFICATIONS_MOBILE_TOTALS_STUDENT_DETAILS:
      return {
        ...state,
        notificationsTotalsStudentsDetails: payload?.data?.result || [],
        notificationsTotalsStudentsDetailsStatus: payload?.status || false,
        notificationsTotalsStudentsDetailsMsg: payload?.msg || 'Something Went Wrong',
        notificationsTotalsStudentsDetailsTotalItems: payload?.data?.totalItems || 0,
        notificationsTotalsStudentsDetailsCurrentPage: payload?.data?.currentPage || 0
      };
    case GET_SHORT_REPORT:
      return {
        ...state,
        shortReportList: payload?.data?.result || [],
        shortReportListStatus: payload?.status || false,
        shortReportListMsg: payload?.msg || 'Something Went Wrong',
        shortReportListTotalItems: payload?.data?.totalItems || 0,
        shortReportListCurrentPage: payload?.data?.currentPage || 0
      };
    case GET_NOTIFICATION_BY_TEACHER:
      return {
        ...state,
        notificationByTeacher:payload?.data
      };
      
    default:
      return state;
  }
};

export default adminArea;