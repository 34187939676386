import {Select} from 'antd';

const {Option} = Select;

export const MasterFeesTypeList = [{id: 1, name: 'Mandatory', ar_name: 'إلزامي'}, {id: 2, name: 'Optional', ar_name: 'اختياري'}];
export const MasterFeesBankSaveList = [{id: 1, name: 'Bank', ar_name: 'مصرف'}, {id: 2, name: 'Save', ar_name: 'حفظ'}];
export const Fees_Status = [{id: 1, name: 'Active', ar_name: "فعال"}, {id: 2, ar_name: "غير فعال", name: 'De active'}];

export const schoolPeriodical = [
  {id: 1, ar_name: "1st Periodical", name: '1st Periodical', grade_type_ids: [2, 3, 4, 5]},
  {id: 2, ar_name: "2nd Periodical", name: '2nd Periodical', grade_type_ids: [2, 3, 4, 5]},
  {id: 3, ar_name: "3rd Periodical", name: '3rd Periodical', grade_type_ids: [2, 3, 4, 5]},
  {id: 4, ar_name: "4th Periodical", name: '4th Periodical', grade_type_ids: [2, 3, 4, 5]},
  {id: 5, ar_name: "Final Result", name: 'Final Result', grade_type_ids: [2, 3, 4, 5]},
  {id: 6, ar_name: "1st Quarter", name: '1st Quarter', grade_type_ids: [2]},
  {id: 7, ar_name: "1st Semester", name: '1st Semester', grade_type_ids: [2]},
  {id: 8, ar_name: "3rd Quarter", name: '3rd Quarter', grade_type_ids: [2]},
  {id: 9, ar_name: "2nd Semester", name: '2nd Semester', grade_type_ids: [2]},
  {id: 10, ar_name: "End Of Year", name: 'End Of Year', grade_type_ids: [2]},
];
export const yesOrNoList = [
  {id: 0, ar_name: "لا", name: 'No'},
  {id: 1, ar_name: "نعم", name: 'Yes'},
];
export const bookCaseList = [
  {id: 0, ar_name: "لا يعار", name: 'No'},
  {id: 1, ar_name: "يعار", name: 'Yes'},
];
export const bookLangList = [
  {id: 0, ar_name: "انجليزي", name: 'English'},
  {id: 1, ar_name: "عربي", name: 'Arabic'},
];
export const certificateStatusList = [
  {
    id: 2,
    name: 'Fee due',
    ar_name: 'رسوم مستحقة'
  },
  {
    id: 3,
    name: 'management review',
    ar_name: 'مراجعة الإدارة'
  },
  {
    id: 7,
    name: 'ملاحظات أكاديمية',
    ar_name: 'ملاحظات أكاديمية'
  },
  {
    id: 8,
    name: 'ملاحظات أكاديمية&رسوم مستحقة',
    ar_name: 'ملاحظات أكاديمية&رسوم مستحقة'
  },
  {
    id: 9,
    name: 'ملاحظات أكاديمية&مراجعة الإدارة',
    ar_name: 'ملاحظات أكاديمية&مراجعة الإدارة'
  },
  {
    id: 10,
    name: 'مراجعة الادارة&رسوم مستحقة',
    ar_name: 'مراجعة الادارة&رسوم مستحقة'
  },
  
];
export const schoolCalenderPeriodical = [
  {id: 1, ar_name: "School Calendar", name: 'School Calendar'},
  {id: 2, ar_name: "1st Periodical", name: '1st Periodical'},
  {id: 3, ar_name: "2nd Periodical", name: '2nd Periodical'},
  {id: 4, ar_name: "3rd Periodical", name: '3rd Periodical'},
  {id: 5, ar_name: "4th Periodical", name: '4th Periodical'}
];
export const coursePlaneTypes = [
  {id: 1, ar_name: "خطة الدرس", name: 'Lesson Plan', haveDescription: false},
  {id: 2, ar_name: "الواجب المنزلي", name: 'Home Work', haveDescription: true},
  {id: 3, ar_name: "المشروع", name: 'Project', haveDescription: true},
  {id: 4, ar_name: "دليل الدراسة", name: 'Study Guide', haveDescription: true}
];
export const sendToTypesList = [
  {id: 1, ar_name: "المراحل الدراسية", name: 'School Type'},
  {id: 2, ar_name: "الصفوف الدراسية", name: 'School Grade'},
  {id: 3, ar_name: "الفصول الدراسية", name: 'School Class'}
];
export const gradingSystemTypes = [
  {id: 1, ar_name: "Grade Point Average", name: 'Grade Point Average', showGradeBesideName:true},
  {id: 2, ar_name: "Honors/Pre-Ap Grade Point", name: 'Honors/Pre-Ap Grade Point' , showGradeBesideName:false},
  {id: 3, ar_name: "Advanced Placement", name: 'Advanced Placement', showGradeBesideName:false}
];
export const semesterBasedCourse = [
  {id: 1, name: "1sem", ar_name: 'الترم الاول'},
  {id: 2, name: "2sem", ar_name: 'الترم الثاني'},
]
export const subjectTypes = [
  {id: 1, ar_name: "اجباري", name: 'Core '},
  {id: 2, ar_name: "اختياري", name: 'Elective'}
];
export const AttachmentTypes = [
  {id: 1, ar_name: "إرفاق ملف", name: 'Upload file'},
  {id: 2, ar_name: "إرفاق رابط", name: 'Attachment Link'}
];
export const PrintModulesTableData = [
  {
    id: 1,
    report_name: "Clearance",
    report_ar_name: "اخلاء طرف",
  },
  {
    id: 2,
    report_name: "Commencing Work",
    report_ar_name: "اشعار مباشرة عمل",
  },
  {
    id: 3,
    report_name: "Leave Requisition Form",
    report_ar_name: "طلب اجازة",
  },
  {
    id: 4,
    report_name: "Loan Request",
    report_ar_name: "طلب قرض حسن",
  },
  {
    id: 5,
    report_name: "Permission - Late Arrival Form",
    report_ar_name: "نموذج استئذان/تاخير",
  },
  {
    id: 6,
    report_name: "Resignation Letter",
    report_ar_name: "طلب استقالة",
  },
];

export const ar_months = ["يناير", "فبراير", "مارس", "إبريل", "مايو", "يونيو","يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"];
export const en_months = [ "January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December" ];
export const ar_days = ["الأحد", "الأثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة","السبت"];
export const en_days = [ "Synday", "Monday", "Tuesday", "Wednesday", "Thursday","Friday","Saturday"];

export const lateArrivalRequestReasons = [
  {id:1, name:"Personal/Family issues",ar_name:"أسباب عائلية / شخصية"},
  {id:2, name:"Official Business",ar_name:"معاملات رسمية"},
  {id:3, name:"Work Related",ar_name:" متطلبات العمل"},
  {id:4, name:"Other",ar_name:"اخرى"},
]


export const  MonthDaysAR = ['الأول',"الثاني","الثالث","الرابع", "الخامس","السادس","السابع","الثامن","التاسع","العاشر","الحادي عشر","الثاني عشر","الثالث عشر"
  ,"الرابع عشر","الخامس عشر","السادس عشر","السابع عشر","الثامن عشر","التاسع عشر","العشرون","الحادي و العشرون","الثاني و العشرون","الثالث و العشرون","الرابع و العشرون",
  "الخامس و العشرون","السادس و العشرون","السابع و العشرون","الثامن و العشرون","التاسع و العشرون","الثلاثون","الحادي و الثلاثون"
]


export const vacationRequestReasons = [
  {id:1, name:"Annual",ar_name:"دورية"},
  {id:2, name:"Emergency",ar_name:"طارئة"},
  {id:3, name:"Unpaid",ar_name:"بدون راتب"},
  {id:4, name:"Maternity",ar_name:"الولادة"},
  {id:5, name:"Death",ar_name:"الوفاة"},
  {id:6, name:"Other",ar_name:"اخرى"},
]

export const EMPLOYEES_PHONES= 'EMPLOYEES_PHONES';
export const EMPLOYEES_NATIONALITIES = 'EMPLOYEES_NATIONALITIES';
export const EMPLOYEES_RESIDENCY_END_DATE = 'EMPLOYEES_RESIDENCY_END_DATE';
export const EMPLOYEES_PASSPORTS_EXPIRY_DATE = 'EMPLOYEES_PASSPORTS_EXPIRY_DATE';
export const EMPLOYEES_DEPARTMENTS = 'EMPLOYEES_DEPARTMENTS';
export const EMPLOYEES_EMPLOYMENT_END_DATE = 'EMPLOYEES_EMPLOYMENT_END_DATE';
export const JANITOR_EMPLOYEES_SPONSER_TYPE = 'JANITOR_EMPLOYEES_SPONSER_TYPE';

export const reportTitles = [
  {id:1,ar_name:'كشف ارقام هواتف الموظفين',name:'Employees Phone Numbers List', key:EMPLOYEES_PHONES},
  {id:2,ar_name:'كشف اسماء الموظفين حسب الجنسية',name:'Employees Names According to Nationality', key:EMPLOYEES_NATIONALITIES},
  {id:3,ar_name:'كشف اسماء الموظفين المنتهية إقامتهم',name:'Employees with Expiring Residency', key: EMPLOYEES_RESIDENCY_END_DATE},
  {id:4,ar_name:'كشف اسماء الموظفين المنتهية جوازاتهم',name:'Employees with Expiring Passports', key: EMPLOYEES_PASSPORTS_EXPIRY_DATE},
  {id:5,ar_name:'كشف اسماء الموظفين والمهنة حسب الاقسام',name:'Employees Departements Name List', key: EMPLOYEES_DEPARTMENTS},
  {id:6,ar_name:'كشف انتهاء خدمات الموظفين للعام الدراسي (العام الحالي)',name:'Employees with Expiring Services (This Year)', key: EMPLOYEES_EMPLOYMENT_END_DATE},
  {id:7,ar_name:'كشف اسماء العاملات بوظيفة(عامل/حارس) فقط',name:'Female Employees List (Guard/Worker) Only', key: JANITOR_EMPLOYEES_SPONSER_TYPE},
]

export const schoolPeriodicalListOptions = (lang) => schoolPeriodical && schoolPeriodical.map((data, index) => {
  return <Option key={index} value={data.id}>{lang === 'ar' ? data.ar_name : data.name}</Option>
});
export const gradingSystemTypesListOptions = (lang) => gradingSystemTypes && gradingSystemTypes.map((data, index) => {
  return <Option key={index} value={data.id}>{lang === 'ar' ? data.ar_name : data.name}</Option>
});
export const subjectTypesListOptions = (lang) => subjectTypes && subjectTypes.map((data, index) => {
  return <Option key={index} value={data.id}>{lang === 'ar' ? data.ar_name : data.name}</Option>
});
export const schoolCalenderPeriodicalOptions = (lang) => schoolCalenderPeriodical && schoolCalenderPeriodical.map((data, index) => {
  return <Option key={index} value={data.id}>{lang === 'ar' ? data.ar_name : data.name}</Option>
});
export const MasterFeesTypeListOptions = (lang) => MasterFeesTypeList && MasterFeesTypeList.map((data, index) =>
  <Option key={index} value={data.id}>{lang === 'ar' ? data.ar_name : data.name}</Option>)
;
export const MasterFeesBankSaveListOptions = (lang) => MasterFeesBankSaveList && MasterFeesBankSaveList.map((data, index) =>
  <Option key={index} value={data.id}>{lang === 'ar' ? data.ar_name : data.name}</Option>
);
export const Fees_StatusListOptions = (lang) => Fees_Status && Fees_Status.map((data, index) =>
  <Option key={index} value={data.id}>{lang === 'ar' ? data.ar_name : data.name}</Option>
);
export const semesterBasedCourseOptions = (lang) => semesterBasedCourse && semesterBasedCourse.map((data, index) =>
  <Option key={index} value={data.id}>{lang === 'ar' ? data.ar_name : data.name}</Option>
);
export const yesOrNoListOptions = (lang) => yesOrNoList && yesOrNoList.map((data, index) =>
  <Option key={index} value={data.id}>{lang === 'ar' ? data.ar_name : data.name}</Option>
);
export const bookCaseListOptions = (lang) => bookCaseList && bookCaseList.map((data, index) =>
  <Option key={index} value={data.id}>{lang === 'ar' ? data.ar_name : data.name}</Option>
);
export const bookLangListOptions = (lang) => bookLangList && bookLangList.map((data, index) =>
  <Option key={index} value={data.id}>{lang === 'ar' ? data.ar_name : data.name}</Option>
);

export const gradingPoints = [
  //Advanced Placement
  {
    id: 1,
    marks_from: 97,
    marks_to: 100,
    points: 5.00,
    letter_grade: 'A+',
    subject_system_type_id: 3
  },
  {
    id: 2,
    marks_from: 93,
    marks_to: 96,
    points: 5.00,
    letter_grade: 'A',
    subject_system_type_id: 3
  },
  {
    id: 3,
    marks_from: 90,
    marks_to: 92,
    points: 4.70,
    letter_grade: 'A-',
    subject_system_type_id: 3
  },
  {
    id: 4,
    marks_from: 87,
    marks_to: 89,
    points: 4.30,
    letter_grade: 'B+',
    subject_system_type_id: 3
  },
  {
    id: 5,
    marks_from: 83,
    marks_to: 86,
    points: 4.00,
    letter_grade: 'B',
    subject_system_type_id: 3
  },
  {
    id: 6,
    marks_from: 80,
    marks_to: 82,
    points: 3.70,
    letter_grade: 'B-',
    subject_system_type_id: 3
  },
  {
    id: 7,
    marks_from: 77,
    marks_to: 79,
    points: 3.30,
    letter_grade: 'C+',
    subject_system_type_id: 3
  },
  {
    id: 8,
    marks_from: 73,
    marks_to: 76,
    points: 3.00,
    letter_grade: 'C',
    subject_system_type_id: 3
  },
  {
    id: 9,
    marks_from: 70,
    marks_to: 72,
    points: 2.70,
    letter_grade: 'C-',
    subject_system_type_id: 3
  },
  {
    id: 10,
    marks_from: 67,
    marks_to: 69,
    points: 2.30,
    letter_grade: 'D+',
    subject_system_type_id: 3
  },
  {
    id: 11,
    marks_from: 63,
    marks_to: 66,
    points: 2.00,
    letter_grade: 'D',
    subject_system_type_id: 3
  },
  {
    id: 12,
    marks_from: 60,
    marks_to: 62,
    points: 2.00,
    letter_grade: 'D-',
    subject_system_type_id: 3
  },
  {
    id: 13,
    marks_from: 0,
    marks_to: 60,
    points: 1.00,
    letter_grade: 'F',
    subject_system_type_id: 3
  },

  //Honors/Pre-Ap Grade Point
  {
    id: 14,
    marks_from: 97,
    marks_to: 100,
    points: 4.50,
    letter_grade: 'A+',
    subject_system_type_id: 2
  },
  {
    id: 15,
    marks_from: 93,
    marks_to: 96,
    points: 4.50,
    letter_grade: 'A',
    subject_system_type_id: 2
  },
  {
    id: 16,
    marks_from: 90,
    marks_to: 92,
    points: 4.20,
    letter_grade: 'A-',
    subject_system_type_id: 2
  },
  {
    id: 17,
    marks_from: 87,
    marks_to: 89,
    points: 3.80,
    letter_grade: 'B+',
    subject_system_type_id: 2
  },
  {
    id: 18,
    marks_from: 83,
    marks_to: 86,
    points: 3.50,
    letter_grade: 'B',
    subject_system_type_id: 2
  },
  {
    id: 19,
    marks_from: 80,
    marks_to: 82,
    points: 3.20,
    letter_grade: 'B-',
    subject_system_type_id: 2
  },
  {
    id: 20,
    marks_from: 77,
    marks_to: 79,
    points: 2.80,
    letter_grade: 'C+',
    subject_system_type_id: 2
  },
  {
    id: 21,
    marks_from: 73,
    marks_to: 76,
    points: 2.50,
    letter_grade: 'C',
    subject_system_type_id: 2
  },
  {
    id: 22,
    marks_from: 70,
    marks_to: 72,
    points: 2.20,
    letter_grade: 'C-',
    subject_system_type_id: 2
  },
  {
    id: 23,
    marks_from: 67,
    marks_to: 69,
    points: 1.80,
    letter_grade: 'D+',
    subject_system_type_id: 2
  },
  {
    id: 24,
    marks_from: 63,
    marks_to: 66,
    points: 1.50,
    letter_grade: 'D',
    subject_system_type_id: 2
  },
  {
    id: 26,
    marks_from: 60,
    marks_to: 62,
    points: 1.50,
    letter_grade: 'D-',
    subject_system_type_id: 2
  },
  {
    id: 26,
    marks_from: 0,
    marks_to: 60,
    points: 0.00,
    letter_grade: 'F',
    subject_system_type_id: 2
  },

  //Grade Point Average
  {
    id: 27,
    marks_from: 97,
    marks_to: 100,
    points: 4.00,
    letter_grade: 'A+',
    subject_system_type_id: 1
  },
  {
    id: 28,
    marks_from: 93,
    marks_to: 96,
    points: 4.00,
    letter_grade: 'A',
    subject_system_type_id: 1
  },
  {
    id: 29,
    marks_from: 90,
    marks_to: 92,
    points: 3.70,
    letter_grade: 'A-',
    subject_system_type_id: 1
  },
  {
    id: 30,
    marks_from: 87,
    marks_to: 89,
    points: 3.30,
    letter_grade: 'B+',
    subject_system_type_id: 1
  },
  {
    id: 31,
    marks_from: 83,
    marks_to: 86,
    points: 3.00,
    letter_grade: 'B',
    subject_system_type_id: 1
  },
  {
    id: 32,
    marks_from: 80,
    marks_to: 82,
    points: 2.70,
    letter_grade: 'B-',
    subject_system_type_id: 1
  },
  {
    id: 33,
    marks_from: 77,
    marks_to: 79,
    points: 2.30,
    letter_grade: 'C+',
    subject_system_type_id: 1
  },
  {
    id: 34,
    marks_from: 73,
    marks_to: 76,
    points: 2.00,
    letter_grade: 'C',
    subject_system_type_id: 1
  },
  {
    id: 35,
    marks_from: 70,
    marks_to: 72,
    points: 1.70,
    letter_grade: 'C-',
    subject_system_type_id: 1
  },
  {
    id: 36,
    marks_from: 67,
    marks_to: 69,
    points: 1.30,
    letter_grade: 'D+',
    subject_system_type_id: 1
  },
  {
    id: 37,
    marks_from: 63,
    marks_to: 66,
    points: 1.00,
    letter_grade: 'D',
    subject_system_type_id: 1
  },
  {
    id: 38,
    marks_from: 60,
    marks_to: 62,
    points: 1.00,
    letter_grade: 'D-',
    subject_system_type_id: 1
  },
  {
    id: 39,
    marks_from: 0,
    marks_to: 60,
    points: 0.00,
    letter_grade: 'F',
    subject_system_type_id: 1
  },
];


export const fromFirtsToForthPeriodIds = [1,2,3,4];

export const BUY_ROUTER_SERVICE = 40;
export const BUY_LANLINE_SERVICE = 42;
export const zenServiceCategories = [
  {
    id:1,
    name: '1.5 KD',
    service_type: BUY_ROUTER_SERVICE
  },
  {
    id:2,
    name: '3 KD',
    service_type: BUY_ROUTER_SERVICE
  },
  {
    id:3,
    name: '5 KD',
    service_type: BUY_ROUTER_SERVICE
  },
  {
    id:4,
    name: '10 KD',
    service_type: BUY_ROUTER_SERVICE
  },
  {
    id:5,
    name: '15 KD',
    service_type: BUY_ROUTER_SERVICE
  },
  {
    id:6,
    name: '30 KD',
    service_type: BUY_ROUTER_SERVICE
  },
  {
    id:7,
    name: '10 KD',
    service_type: BUY_LANLINE_SERVICE
  },
  {
    id:8,
    name: '15 KD',
    service_type: BUY_LANLINE_SERVICE
  },
]
